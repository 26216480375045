.landing-page-product-demo-and-value-props {
  .product-demo-content {
    margin: 80px auto 40px;
    padding: 0 20px;
    text-align: center;

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    p {
      margin-bottom: 40px;
    }
  }

  .product-demo-photo {
    background-position-x: 50%;
    background-repeat: no-repeat;

    @include media-breakpoint-between(xs, md) {
      background-size: 362px;
      max-width: 100%;
    }
  }

  .product-demo-empty-overlay {
    height: 237px;
    width: 100%;

    @include media-breakpoint-up(md) {
      height: 600px;
    }
  }

  .product-demo-value-prop {
    height: 296px;
    margin: 0 auto 40px;
    padding: 40px 20px;
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      height: 234px;
      width: 595px;
    }

    @include media-breakpoint-up(xl) {
      height: 296px;
      margin: 0 40px 0 0;
      width: 338px;
    }

    &:last-of-type {
      margin: 0 auto;

      @include media-breakpoint-up(xl) {
        margin-right: 0;
      }
    }

    img {
      margin-bottom: 20px;
      width: 40px;
    }
  }
}

.sohp-container {
  h1 {
    font-family: "Cormorant Garamond", serif;
    font-size: 2.75rem;
    font-weight: $font-weight-normal;
    line-height: 1.25;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 4.375rem;
    }
  }

  h2 {
    font-family: "Gelasio", serif;
    font-size: 1.75rem;
    font-weight: $font-weight-normal;
    margin-bottom: 0;

    @include media-breakpoint-up(xl) {
      font-size: 2.125rem;
    }
  }

  h3 {
    font-size: 1.75rem;
    font-weight: $font-weight-normal;
  }

  .sohp-body-content-container {
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1175px;
    padding: 40px 20px;
    width: 100%;

    @include media-breakpoint-up(md) {
      padding: 80px 40px;
    }
  }

  ul {
    margin: 40px 0;
    padding-left: 25px;
  }

  li {
    margin-bottom: 10px;
    padding-left: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .sohp-section-arrow {
    height: 36px;
    margin-right: -18px;
    margin-top: -18px;
    position: absolute;
    right: 50%;
    transform: rotate(45deg);
    width: 36px;
  }

  .subheader {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }
  }

  .underline-link {
    color: $darker-gray-text;
    font-weight: $font-weight-light;
    text-decoration: underline;
  }

  .cta {
    .cta-btn-group {
      margin-top: 0;

      &.flex-column {
        .btn {
          margin-bottom: 10px;
        }
      }

      .btn {
        @include media-breakpoint-between(xs, md) {
          max-width: none;
        }
      }
    }
  }

  .sohp-above-fold-container {
    .sohp-body-content-container {
      @include media-breakpoint-between(xs, md) {
        padding: 40px 20px 0;
      }

      @include media-breakpoint-between(md, xl) {
        padding: 40px 40px 0;
      }
    }

    .sohp-above-fold-content {
      margin: 0 auto;

      @include media-breakpoint-between(xs, md) {
        width: 100%;
      }

      @include media-breakpoint-up(xl) {
        margin: 30px 80px 0 0;
      }

      ul {
        list-style-image: url("../svg/right-arrow-primary.svg");
      }

      .cta {
        .cta-btn-group {
          @include media-breakpoint-between(xs, md) {
            margin-bottom: 20px;
          }

          @include media-breakpoint-up(xl) {
            margin-bottom: 40px;
          }
        }
      }
    }

    .memorial-collage-desktop {
      @include div-same-size-as-background-img(
        "../img/sohp-collage-desktop.jpg",
        1095px,
        568px
      );

      background-position-x: 100%;
      max-width: 475px;
    }

    .memorial-collage {
      background-image: url("../img/sohp-collage-mobile.jpg");
      background-position-x: 50%;
      background-repeat: no-repeat;
      background-size: 670px auto;
      height: 410px;
      margin: 0 auto 40px;
      width: 100%;

      @include media-breakpoint-up(md) {
        background-image: url("../img/sohp-collage-tablet.jpg");
        background-size: 885px auto;
        height: 220px;
        margin-top: 40px;
      }
    }

    .sohp-section-arrow {
      background-color: $white;
    }
  }

  .sohp-planning-resources-container {
    background-image: radial-gradient(
      circle at 50% 100%,
      $radial-gradient-teal,
      $radial-gradient-very-dark-teal 250%
    );
    color: $white;

    .sohp-body-content-container {
      max-width: 754px;
      text-align: center;

      h2 {
        margin: 0 0 20px;

        @include media-breakpoint-up(xl) {
          margin-top: 40px;
        }
      }

      .cta {
        .cta-btn-group {
          .btn-outline-secondary {
            border-color: $white;
            color: $white;
          }
        }
      }
    }

    .sohp-section-arrow {
      background-color: #2cafb9; // stylelint-disable-line color-no-hex
    }
  }

  .sohp-checklist-container {
    background-color: $white;
    filter: drop-shadow(0 1px 5px $black-with-10-percent-opacity);

    .sohp-body-content-container {
      @include media-breakpoint-between(xs, xl) {
        justify-content: center;
      }
    }

    .checklist-screenshot {
      background-image: url("../img/sohp-checklist-screenshot.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      height: calc(65vw);
      margin: 40px auto 0;
      max-width: 100%;
      width: calc(100% - 40px);

      @include media-breakpoint-up(md) {
        height: auto;
        margin: 0;
        max-width: 539px;
        width: 313px;
      }

      @include media-breakpoint-up(xl) {
        width: 100%;
      }
    }

    .sohp-checklist-content {
      margin-left: 0;
      margin-top: 0;
      max-width: 100%;

      @include media-breakpoint-up(md) {
        margin-left: 40px;
        margin-top: 15px;
        max-width: 335px;
      }

      @include media-breakpoint-up(xl) {
        margin-left: 80px;
        margin-top: 110px;
        max-width: 462px;
      }

      h2 {
        @include media-breakpoint-between(xs, md) {
          text-align: center;
        }
      }

      ul {
        list-style-image: url("../svg/right-arrow-blue.svg");

        li {
          @include media-breakpoint-between(xs, xl) {
            font-size: 1.125rem;
            line-height: 1.5;
          }
        }
      }

      .cta {
        .cta-btn-group {
          text-align: left;
        }
      }
    }

    .sohp-section-arrow {
      background-color: $white;
    }
  }

  .sohp-products-container {
    background-color: $light-blue-with-95-percent-opacity;

    .product-demo-content {
      margin: 0 auto;
      max-width: 754px;
      padding: 80px 20px 40px;
      text-align: center;

      @include media-breakpoint-up(md) {
        padding: 80px 40px;
      }

      h2 {
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 40px;
      }
    }

    .product-demo-photo {
      background-image: url("../img/sohp-products-mockup.png");
      background-position-x: 50%;
      background-repeat: no-repeat;
      background-size: 362px;
      max-width: 100%;

      @include media-breakpoint-up(md) {
        background-size: 674px auto;
        max-width: none;
      }

      @include media-breakpoint-up(xl) {
        background-size: 894px auto;
      }
    }

    .product-demo-empty-overlay {
      height: 205px;
      width: 100%;

      @include media-breakpoint-up(md) {
        height: 382px;
      }

      @include media-breakpoint-up(xl) {
        height: 500px;
      }
    }

    .sohp-section-arrow {
      background-color: $white;
    }
  }

  .sohp-memorial-container {
    .product-demo-content {
      max-width: 754px;

      h2 {
        margin-bottom: 20px;
      }
    }

    .product-demo-photo {
      background-image: url("../img/sohp-memorial-mockup.png");
      background-size: 362px;
      max-width: 100%;

      @include media-breakpoint-up(md) {
        background-size: 623px auto;
      }

      @include media-breakpoint-up(xl) {
        background-size: 894px auto;
      }
    }

    .product-demo-empty-overlay {
      @include media-breakpoint-between(xs, md) {
        height: 187px;
      }

      @include media-breakpoint-between(md, xl) {
        height: 345px;
      }
    }

    .product-demo-value-props {
      background-color: $light-blue-with-95-percent-opacity;

      p {
        font-weight: $font-weight-light;
      }
    }

    .product-demo-value-prop {
      @include el-border;

      background-color: $white;
    }

    .sohp-section-arrow {
      background-color: $light-blue;
      z-index: $zindex-default;
    }
  }

  .sohp-articles-container {
    background-image: radial-gradient(
      circle at 50% 56%,
      $radial-gradient-blue,
      $radial-gradient-very-dark-teal 230%
    );

    .sohp-articles-inner-container {
      color: $white;
      margin: 0 auto;
      padding: 40px 20px 80px;
      text-align: center;
      width: 100%;

      @include media-breakpoint-up(md) {
        padding: 80px 40px;
        width: 768px;
      }

      @include media-breakpoint-up(xl) {
        width: 908px;
      }

      .sohp-articles-content-container {
        margin-bottom: 40px;
        width: 100%;

        @include media-breakpoint-up(md) {
          margin: 0 auto;
          width: 576px;
        }

        @include media-breakpoint-up(xl) {
          margin-bottom: 80px;
          width: auto;
        }
      }

      .underline-link {
        color: $white;
        font-weight: $font-weight-normal;
      }
    }

    h2 {
      margin-bottom: 20px;
    }

    .mini-section-title {
      margin-bottom: 20px;
      text-align: center;

      @include media-breakpoint-up(xl) {
        text-align: left;
      }
    }

    a {
      .sohp-article-preview {
        filter: drop-shadow(0 1px 5px $black-with-10-percent-opacity);
        height: 338px;
        margin: 0 auto 20px;
        text-align: left;
        width: 100%;

        @include media-breakpoint-up(md) {
          height: 242px;
          width: 687px;
        }

        @include media-breakpoint-up(xl) {
          margin-bottom: 40px;
          width: 828px;
        }
      }

      &:last-of-type,
      &:first-of-type {
        .sohp-article-preview {
          margin-bottom: 80px;
        }
      }

      &:last-of-type {
        .sohp-article-preview {
          @include media-breakpoint-between(xs, md) {
            margin-bottom: 40px;
          }
        }
      }
    }

    .sohp-article-preview-photo {
      background-color: $light-blue;
      background-size: cover;
      border-radius: $border-radius-sm $border-radius-sm 0 0;
      height: 168px;
      min-width: 100%;

      @include media-breakpoint-up(md) {
        border-radius: $border-radius-sm 0 0 $border-radius-sm;
        height: auto;
        min-width: 249px;
      }

      @include media-breakpoint-up(xl) {
        min-width: 300px;
      }
    }

    .sohp-article-preview-content {
      background-color: $white;
      border-radius: 0 0 $border-radius-sm $border-radius-sm;
      height: 170px;
      padding: 20px;

      @include media-breakpoint-up(md) {
        border-radius: 0 $border-radius-sm $border-radius-sm 0;
        height: auto;
        padding: 40px;
      }
    }

    .sohp-article-preview-title {
      color: $primary-brand-color;
      font-size: 1.5rem;
      font-weight: $font-weight-normal;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-breakpoint-up(md) {
        font-size: 1.75rem;
        margin-bottom: 20px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 2.125rem;
      }
    }

    .sohp-article-preview-body {
      color: $darker-gray-text;
      font-size: 1.125rem;
      font-weight: $font-weight-light;
      max-height: 3em;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-breakpoint-up(xl) {
        font-size: 1.3125rem;
      }
    }

    .hollow-cta {
      margin-top: 40px;

      a {
        display: flex;
        justify-content: center;

        img {
          margin-left: 10px;
        }

        &:hover {
          color: $white;
          font-weight: $font-weight-normal;
        }
      }
    }

    .sohp-section-arrow {
      background-color: #3973a9; // stylelint-disable-line color-no-hex

      @include media-breakpoint-up(md) {
        background-color: #3976ad; // stylelint-disable-line color-no-hex
      }

      @include media-breakpoint-up(xl) {
        background-color: #3d79b3; // stylelint-disable-line color-no-hex
      }
    }
  }

  .sohp-search-memorials-container {
    text-align: center;

    .sohp-body-content-container {
      @include media-breakpoint-between(xs, md) {
        padding-top: 80px;
      }
    }

    h2 {
      margin-bottom: 20px;
    }

    .sohp-search-memorials-form {
      margin: 0 auto;
      padding-top: 40px;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 675px;
      }

      .el-input-group {
        margin: 0 0 20px;

        @include media-breakpoint-up(md) {
          margin: 9px 20px 0 0;
        }

        .borderless-input {
          font-size: 1.3125rem;
          height: 2.5rem;

          @include media-breakpoint-between(xs, md) {
            font-size: 0.9375rem;
            max-width: 100%;
          }
        }
      }

      .cta {
        .cta-btn-group {
          .btn {
            min-width: 168px;

            img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.sohp-h2-white {
  color: $white;
}

.sohp-body-text {
  font-size: 1.125rem;
  font-weight: $font-weight-light;
  margin-bottom: 0;

  @include media-breakpoint-up(xl) {
    font-size: 1.5rem;
  }
}

.sohp-body-text-white {
  color: $white;
}

.learn-more-cta {
  align-self: flex-start;
  color: $primary-brand-color;
  cursor: pointer;
  font-size: 1.3125rem;
  font-weight: $font-weight-normal;

  img {
    padding-left: 7px;
  }
}

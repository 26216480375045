// Memorial Websites LP
//
// This used to be the SOHP

.memorial-websites-lp-body {
  margin-bottom: 100px;
  padding: 20px;

  @include media-breakpoint-up(md) {
    margin-bottom: 60px;
    padding: 40px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding: 0;
  }

  h1 {
    color: $dark-gray-text;
    font-size: 32px;
    font-weight: $font-weight-semibold;
    letter-spacing: 0.1px;
    width: auto;

    @include media-breakpoint-up(md) {
      font-size: 32px;
      letter-spacing: 0.1px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 45px;
      letter-spacing: 0.2px;
      width: 480px;
    }
  }

  h2 {
    color: $dark-gray-text;
    font-size: 28px;
    font-weight: $font-weight-semibold;
    letter-spacing: 0.4px;

    @include media-breakpoint-between(xs, md) {
      line-height: 1.39;
    }

    @include media-breakpoint-up(md) {
      font-size: 34px;
      letter-spacing: 0.5px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 40px;
      letter-spacing: 0.6px;
    }
  }

  h3 {
    color: $dark-gray-text;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.4px;

    @include media-breakpoint-up(md) {
      font-size: 28px;
      letter-spacing: 0.5px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 34px;
      letter-spacing: 0.6px;
    }
  }

  p {
    color: $dark-gray-text;
    font-size: 1.25rem;
    font-weight: $font-weight-light;

    @include media-breakpoint-up(md) {
      padding-top: 15px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
  }

  .btn {
    font-size: 18px;
    letter-spacing: 0.07rem;
    margin: 52px 0 86px;
    max-width: $el-button-length;
    padding: 15px 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin: 80px 0 0;
      max-width: none;
      padding: 16px 0;
      width: 240px;
    }
  }

  .feature-icon {
    height: 35px;

    @include media-breakpoint-up(md) {
      height: 54px;
    }
  }

  .rhombus {
    margin: auto -20px;

    @include media-breakpoint-up(md) {
      height: 520px;
      margin: auto -40px;
    }

    @include media-breakpoint-up(lg) {
      height: 664px;
      margin: auto -80px;
    }

    .rhombus-text {
      padding: 40px 30px;
      width: 375px;

      @include media-breakpoint-up(md) {
        padding: 0;
        width: 685px;
      }

      @include media-breakpoint-up(lg) {
        width: 883px;
      }

      p {
        font-size: 20px;
        font-weight: $font-weight-light;
        letter-spacing: 0.5px;
        line-height: 1.53;

        @include media-breakpoint-up(md) {
          font-size: 28px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 24px;
          letter-spacing: 0.6px;
        }
      }
    }
  }

  .homepage-above-fold {
    align-self: center;
    height: 483px;
    margin-bottom: 20px;
    max-width: 331px;
    padding-bottom: 80px;
    padding-top: 20px;
    width: 100%;

    @include media-breakpoint-up(md) {
      height: auto;
      max-width: 100%;
      padding-top: 80px;
      width: 688px;
    }

    @include media-breakpoint-up(lg) {
      align-self: auto;
      margin: 0 auto;
      width: 1200px;
    }

    .above-fold-content {
      max-width: 332px;

      @include media-breakpoint-up(lg) {
        max-width: none;
        padding-top: 30px;
      }

      h1 {
        max-width: 332px;
        padding-right: 70px;

        @include media-breakpoint-up(md) {
          max-width: 262px;
          padding-right: 0;
        }

        @include media-breakpoint-up(lg) {
          max-width: none;
        }
      }

      p {
        color: $darker-gray-text;
        margin-bottom: 0;
        margin-top: 28px;
        max-width: 480px;

        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
      }
    }

    .above-fold-picture-container {
      @include media-breakpoint-between(xs, md) {
        align-self: flex-end;
      }
    }

    .above-fold-picture {
      background-image: url("../img/product_landing/computer-memorials.png");
      background-repeat: no-repeat;
      background-size: 100%;
      margin-right: 20px;
      min-height: 432px;
      position: relative;
      top: -230px;
      width: 203px;

      @include media-breakpoint-up(md) {
        margin-right: 0;
        position: static;
        top: auto;
        width: 329px;
      }

      @include media-breakpoint-up(lg) {
        width: 647px;
      }
    }

    .btn {
      @include media-breakpoint-between(xs, md) {
        position: relative;
        top: 96px;
      }
    }
  }

  .mission-rhombus {
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 120px;
    padding: 40px 0;

    @include media-breakpoint-up(md) {
      height: 860px;
      margin-top: 0;
      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      left: 80px;
      max-width: 100%;
      position: relative;
    }
  }

  .feature-menu {
    margin-top: 67px;
    padding: 0 20%;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-top: 80px;
      padding: 0;
    }

    .feature-anchor-links-container {
      margin-top: 53px;

      @include media-breakpoint-up(lg) {
        margin-top: 73px;
      }
    }

    .feature-anchor-links-subcontainer {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;

      a {
        cursor: pointer;
      }
    }

    .feature-anchor-link {
      border-radius: $border-radius-lg;
      color: $primary-brand-color;
      cursor: pointer;
      font-size: 24px;
      font-weight: $font-weight-semibold;
      height: 176px;
      letter-spacing: 0.4px;
      line-height: 1.3;
      margin: 0 10px;
      padding: 0 33px;
      width: 279px;

      &:hover {
        background-color: $light-blue;
        cursor: pointer;
      }

      img {
        margin: 27px 65px 12px;
      }
    }

    .memories-feature-button {
      padding: 0 44px;
    }
  }

  .homepage-features {
    margin: 0 auto;
    max-width: 331px;
    padding-bottom: 80px;
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: none;
      min-width: auto;
      width: auto;
    }

    @include media-breakpoint-up(lg) {
      max-width: 1276px;
      min-width: 1090px;
      width: 1276px;
    }

    p {
      font-size: 16px;
      letter-spacing: 0.3px;
      line-height: 1.89;
      margin-bottom: 24px;
      width: auto;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        letter-spacing: 0.3px;
        width: 510px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 21px;
        letter-spacing: 0.4px;
        line-height: 1.62;
        margin-bottom: 1rem;
      }
    }

    .feature-icon {
      margin-bottom: 14px;

      @include media-breakpoint-up(md) {
        margin-bottom: 20px;
      }
    }

    .feature-picture-container {
      @include media-breakpoint-between(xs, md) {
        max-width: 331px;
        width: 100%;
      }
    }

    .feature-picture {
      background-repeat: no-repeat;
      background-size: 100%;
      height: 0;

      @include media-breakpoint-between(xs, md) {
        width: 100%;
      }
    }

    img {
      @include media-breakpoint-between(xs, md) {
        max-width: 100%;
      }
    }

    .feature-container {
      align-items: center;

      @include media-breakpoint-up(lg) {
        align-items: normal;
      }
    }

    .memorial-feature {
      margin-top: 66px;

      @include media-breakpoint-up(md) {
        margin-top: 20px;
        padding-top: 70px;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 60px;
        padding-top: 167px;
      }

      h3,
      p {
        @include media-breakpoint-up(md) {
          width: 472px;
        }
      }

      .memorial-feature-content {
        @include media-breakpoint-up(md) {
          width: 560px;
        }
      }

      .memorial-feature-picture-container {
        @include media-breakpoint-between(xs, md) {
          padding: 19px 20px 0;
        }
      }

      .memorial-feature-picture {
        background-image: url("../img/memorial-site.png");
        padding-top: 53%;

        @include media-breakpoint-up(md) {
          width: 530px;
        }
      }
    }

    .obituary-feature {
      margin-top: 71px;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        padding-top: 71px;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 40px;
        padding-top: 185px;
      }

      .feature-icon {
        margin-left: -6px;
      }

      .obituary-feature-picture-container {
        margin-top: 0;
        padding: 8px 20px 0;

        @include media-breakpoint-up(md) {
          padding: 0;
        }

        @include media-breakpoint-up(lg) {
          margin-top: 31px;
        }
      }

      .obituary-feature-picture {
        background-image: url("../img/obituary.png");
        padding-top: 49%;

        @include media-breakpoint-up(md) {
          width: 502px;
        }
      }
    }

    .invites-feature {
      margin-top: 71px;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        padding-top: 71px;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 80px;
        padding-top: 140px;
      }

      h3 {
        @include media-breakpoint-up(md) {
          width: 460px;
        }
      }

      .invites-feature-picture-container {
        @include media-breakpoint-between(xs, md) {
          padding: 19px 30px 0;
        }
      }

      .invites-feature-picture {
        background-image: url("../img/invitations-and-rsvps.png");
        padding-top: 60%;

        @include media-breakpoint-up(md) {
          width: 559px;
        }
      }
    }

    .memories-feature {
      margin-bottom: 70px;
      margin-top: 71px;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        padding-top: 71px;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 30px;
        padding-top: 180px;
      }

      .memories-feature-content {
        @include media-breakpoint-up(lg) {
          padding-top: 31px;
        }
      }

      .memories-feature-picture-container {
        @include media-breakpoint-between(xs, md) {
          padding: 24px 12px 0 25px;
        }
      }

      .memories-feature-picture {
        background-image: url("../img/memories.png");
        padding-top: 93%;

        @include media-breakpoint-up(md) {
          width: 410px;
        }
      }
    }
  }

  .cta {
    margin-top: 80px;
  }
}

.cta {
  &.index-cta {
    p {
      font-size: 1.5rem;
      padding-top: 2px;
    }
  }
}

.index-background {
  background: $white;
  background-size: 100% 100%;
  margin: 0 auto;
  min-height: 100%;
  position: relative;

  @include media-breakpoint-between(xs, md) {
    padding-bottom: 0;
  }
}

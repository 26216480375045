.resources-lp {
  .resources-lp-above-fold {
    .rhombus {
      background-image: url("../img/product_landing/rhombus-photo-employees-mobile.jpg");
      background-position-y: -100px;
      background-repeat: no-repeat;
      background-size: cover;
      height: auto;

      @include media-breakpoint-up(md) {
        background-image: url("../img/product_landing/rhombus-photo-employees-tablet.jpg");
      }

      @include media-breakpoint-up(lg) {
        background-image: url("../img/product_landing/rhombus-photo-employees-desktop.jpg");
      }

      .rhombus-text {
        margin: 60px 0 100px;
        max-width: none;
        padding: 0 20px;
        width: 100%;

        @include media-breakpoint-up(md) {
          padding: 0;
          width: 460px;
        }

        @include media-breakpoint-up(lg) {
          width: 765px;
        }

        h1 {
          font-size: 1.75rem;
          margin-bottom: 20px;

          @include media-breakpoint-up(md) {
            font-size: 2rem;
          }

          @include media-breakpoint-up(lg) {
            margin-bottom: 40px;
          }
        }

        .subheader {
          margin-bottom: 40px;
        }
      }
    }

    .rhombus-creator-bottom {
      margin-top: -50px;

      @include media-breakpoint-up(md) {
        margin-top: -90px;
      }
    }
  }

  .rhombus-creator {
    transform: rotate(-3.25deg);
  }

  .product-lp-body-container {
    margin-top: -60px;
    position: relative;

    @include media-breakpoint-up(md) {
      margin-top: -100px;
    }
  }
}

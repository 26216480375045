.employee-lp {
  .employee-lp-partner-section {
    &.founders-section {
      margin-top: -40px;
      max-width: none;

      @include media-breakpoint-up(lg) {
        margin-top: -120px;
      }

      .founders-text {
        max-width: 1200px;
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 85%;
        }
      }
    }
  }

  .employee-lp-above-fold {
    .rhombus-creator-bottom {
      height: 100px;

      @include media-breakpoint-up(md) {
        height: 105px;
      }

      @include media-breakpoint-up(lg) {
        height: 205px;
      }
    }
  }
}

.about-us-founders-container {
  .about-us-founder-container {
    &:first-child {
      @include media-breakpoint-up(md) {
        margin-right: 80px;
      }
    }

    .image-container {
      .founder-photo {
        @include el-border;

        max-height: 175px;

        @include media-breakpoint-up(md) {
          max-height: 300px;
        }
      }
    }
  }
}

.news-articles-container {
  height: auto;
  width: auto;

  @include media-breakpoint-up(md) {
    height: 772px;
  }

  @include media-breakpoint-up(xl) {
    height: 512px;
    width: 1024px;
  }

  .news-article-container {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 45%;
    }

    @include media-breakpoint-up(lg) {
      width: 30%;
    }

    .about-us-article-image-container {
      background-position: center;
      background-size: cover;
      height: 110px;
      max-width: 200px;
      object-fit: contain;
    }
  }
}

.about-us-lp {
  .rhombus-creator {
    @include media-breakpoint-up(lg) {
      margin-top: -150px !important;
    }
  }
}

.employee-lp {
  .employee-lp-above-fold {
    .rhombus {
      background-image: url("../img/product_landing/rhombus-photo-employees-mobile.jpg");
      background-position-y: -60px;
      background-repeat: no-repeat;
      background-size: cover;
      height: 600px;

      @include media-breakpoint-up(md) {
        background-image: url("../img/product_landing/rhombus-photo-employees-tablet.jpg");
        height: 571px;
      }

      @include media-breakpoint-up(lg) {
        background-image: url("../img/product_landing/rhombus-photo-employees-desktop.jpg");
        background-position-y: 0;
        height: 665px;
      }

      .rhombus-text {
        padding: 0 20px;
        width: 100%;

        @include media-breakpoint-up(md) {
          padding-bottom: 0;
          width: 460px;
        }

        @include media-breakpoint-up(lg) {
          width: 765px;
        }
      }
    }

    .rhombus-creator-bottom {
      margin-top: -50px;

      @include media-breakpoint-only(md) {
        margin-top: -90px;
      }
    }
  }

  .rhombus-creator {
    transform: rotate(-3.25deg);
  }

  .employee-lp-partner-section {
    margin: -100px auto 0;
    margin-top: -80px;
    max-width: 1080px;
    padding: 20px;

    @include media-breakpoint-up(lg) {
      margin-top: -40px;
      padding: 40px;
    }

    h2 {
      margin-bottom: 40px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 80px;
      }
    }

    .partner-value-prop-card {
      border: 2px solid $secondary-brand-color;
      box-shadow: 0 20px 50px -20px $black-with-10-percent-opacity;
      max-width: 100%;
      padding: 20px 20px 40px;

      @include media-breakpoint-up(md) {
        max-width: 340px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 440px;
        padding: 40px;
      }

      .partner-plan-header {
        @include el-border-bottom;

        font-size: 1.3125rem;

        @include media-breakpoint-up(lg) {
          font-size: 1.5rem;
        }
      }
    }

    h3 {
      margin: 80px 0 40px;

      @include media-breakpoint-between(xs, md) {
        margin-top: 40px;
      }
    }

    .partner-value-prop {
      img {
        margin: 5px 10px 0 0;
        max-width: 27px;
        min-width: 27px;
      }
    }
  }

  .employee-lp-create-memorial {
    background-image: url("../img/product_landing/desk-with-product-mock-mobile.jpg");
    background-position-x: 70%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 765px;
    padding-top: 80px;

    @include media-breakpoint-up(md) {
      background-image: url("../img/product_landing/desk-with-product-mock-tablet.jpg");
      height: 800px;
    }

    @include media-breakpoint-up(lg) {
      background-image: url("../img/product_landing/desk-with-product-mock-desktop.jpg");
      height: 568px;
      padding-top: 40px;
    }

    .create-memorial-content-container {
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 65%;
      }
    }

    .create-memorial-content {
      max-width: 100%;
      padding: 0 20px;
      width: 100%;

      @include media-breakpoint-up(md) {
        padding: 0 40px;
        width: auto;
      }

      @include media-breakpoint-up(lg) {
        max-width: 770px;
      }

      @include media-breakpoint-up(xl) {
        padding-left: 80px;
      }

      .subheader {
        margin-bottom: 40px;

        @include media-breakpoint-up(xl) {
          margin-bottom: 80px;
        }
      }
    }

    .cta {
      .cta-btn-group {
        .btn {
          @include media-breakpoint-between(xs, md) {
            max-width: 100%;
          }
        }
      }
    }
  }

  .full-width-cta {
    font-size: 1.3125rem;
    height: 192px;
    padding: 20px;

    @include media-breakpoint-up(md) {
      height: 140px;
      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }

    .cta {
      @include media-breakpoint-between(xs, md) {
        width: 100%;
      }

      .cta-btn-group {
        .btn {
          --#{$prefix}btn-bg: #{$white};
          --#{$prefix}btn-hover-border-color: #{$white};

          max-width: 100%;

          @include media-breakpoint-up(md) {
            width: 335px;
          }
        }
      }
    }
  }
}

.product-lp-body {
  &.doula-lp-body {
    @include media-breakpoint-between(xs, md) {
      .rhombus-content {
        margin-top: -80px !important;
      }
    }
  }

  &.event-spaces-lp-body {
    @include media-breakpoint-between(xs, md) {
      .cta-rhombus {
        height: 566px;
      }
    }
  }
}

.product-lp {
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      font-size: 2.125rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 2.875rem;
    }
  }

  h2 {
    font-size: 1.75rem;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
    }
  }

  h3 {
    font-size: 1.3125rem;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 2.125rem;
    }
  }

  p {
    &:not(.body-text-large) {
      font-size: 1rem;
      font-weight: $font-weight-light;

      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
      }
    }
  }

  .subheader {
    font-size: 1.3125rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
  }

  .product-lp-body-container {
    margin: 0 auto;
    max-width: 331px;
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 100%;
      width: calc(100vw - 80px);
    }

    @include media-breakpoint-up(lg) {
      width: 1200px;
    }

    &.lp-alerts {
      margin-top: 40px;

      @include media-breakpoint-up(lg) {
        margin-bottom: -40px;
      }
    }
  }

  .product-lp-above-fold {
    max-height: 550px;
    padding: 0 10px 80px;

    @include media-breakpoint-up(md) {
      max-height: none;
      padding: 32px 10px 80px;
    }

    @include media-breakpoint-up(lg) {
      padding: 80px 40px;
    }

    @include media-breakpoint-up(xl) {
      padding: 80px 0;
    }

    &.double-buttons {
      @include media-breakpoint-between(xs, md) {
        max-height: 600px;
      }

      .above-fold-picture {
        @include media-breakpoint-between(xs, md) {
          top: -140px;
        }
      }
    }

    .above-fold-content {
      max-width: 332px;

      @include media-breakpoint-up(md) {
        margin-top: -13px;
      }

      @include media-breakpoint-up(lg) {
        margin-top: -17px;
        max-width: 480px;
      }

      .cta-btn-group {
        @include media-breakpoint-between(xs, md) {
          position: relative;
          top: 205px;
        }

        .btn {
          @include media-breakpoint-between(xs, md) {
            padding: 15px 0;
          }

          @include media-breakpoint-only(md) {
            max-width: 240px;
          }
        }
      }

      .location-and-business-search {
        .nav {
          --#{$prefix}nav-link-padding-x: 0.5rem;
        }
      }
    }

    .above-fold-picture {
      background-repeat: no-repeat;
      background-size: 100%;
      min-height: 200px;
      width: 213px;

      @include media-breakpoint-between(xs, md) {
        margin: 0 auto;
        position: relative;
        top: -75px;
      }

      @include media-breakpoint-up(md) {
        min-height: auto;
        width: 293px;
      }

      @include media-breakpoint-up(lg) {
        min-height: 440px;
        width: 647px;
      }
    }
  }

  .rhombus {
    &.testimonial-rhombus {
      margin: -100px 0 -200px;

      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }

    .rhombus-text {
      width: 100%;

      @include media-breakpoint-between(xs, md) {
        max-width: 331px;
        padding: 40px 30px;
      }

      @include media-breakpoint-up(md) {
        width: 685px;
      }

      @include media-breakpoint-up(lg) {
        width: 883px;
      }

      h4 {
        @include media-breakpoint-between(xs, md) {
          font-weight: $font-weight-normal;
        }
      }
    }
  }

  .blue-rhombus {
    background-repeat: no-repeat;
    background-size: cover;
    height: 566px;

    @include media-breakpoint-between(xs, md) {
      background-position: center;
      margin-top: 100px;
    }

    @include media-breakpoint-up(md) {
      height: 575px;
    }

    @include media-breakpoint-up(lg) {
      height: 860px;
    }

    &.h-auto {
      margin-bottom: -100px;
      padding: 160px 20px 200px;

      @include media-breakpoint-up(md) {
        padding: 160px 0 200px;
      }

      .btn {
        width: $el-button-length;

        @include media-breakpoint-between(xs, md) {
          max-width: 100%;
        }
      }

      .rhombus-text {
        @include media-breakpoint-between(xs, md) {
          margin-top: 60px;
          padding: 0;
        }
      }
    }

    .rhombus-text {
      @include media-breakpoint-between(xs, md) {
        margin-bottom: 40px;
        padding-bottom: 0;
      }

      p {
        font-size: 1.3125rem;

        @include media-breakpoint-between(xs, md) {
          margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
          font-size: 1.5rem;
        }
      }
    }
  }

  .product-lp-value-props-container {
    margin-bottom: -80px;
    padding: 40px 10px 80px;

    @include media-breakpoint-up(lg) {
      padding: 80px 0 0;
    }

    .product-lp-value-props {
      padding: 40px 0 0;

      @include media-breakpoint-up(lg) {
        padding: 60px 0 40px;
      }

      .value-prop {
        margin-bottom: 80px;
        width: 480px;

        @include media-breakpoint-up(lg) {
          margin-right: 50px;
          width: 330px;
        }

        .value-prop-icon {
          height: 100px;
          width: 100px;

          @include media-breakpoint-up(lg) {
            height: 120px;
            width: 120px;
          }

          &.value-prop-truck-icon {
            margin-bottom: -5px;

            @include media-breakpoint-up(lg) {
              margin-bottom: 28px;
              margin-top: 20px;
            }
          }

          &.value-prop-icon-phone {
            height: 80px;
            margin-bottom: 28px;
            margin-top: 20px;
            width: 80px;

            @include media-breakpoint-up(lg) {
              margin-bottom: 68px;
            }
          }
        }
      }
    }
  }

  .rhombus-creator {
    height: 100px;
    margin-left: -50px;
    transform: rotate(3.25deg);
    width: 150%;

    @include media-breakpoint-up(md) {
      height: 205px;
    }
  }

  .rhombus-creator-top {
    margin-bottom: -54px;

    @include media-breakpoint-up(md) {
      margin-bottom: -100px;
    }

    @include media-breakpoint-up(lg) {
      z-index: 1;
    }
  }

  .rhombus-creator-bottom {
    margin-top: -100px;
  }

  .why-rhombus {
    height: auto;

    @include media-breakpoint-up(lg) {
      height: 1114px;
    }

    .rhombus-content-container {
      background: none;
      height: 813px;
      margin-top: -80px;
      padding: 0 10px;
      width: 100%;

      @include media-breakpoint-up(md) {
        padding: 0 0 80px;
      }

      @include media-breakpoint-up(lg) {
        background-color: $slate-gray;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        padding: 0 80px 0 0;
        width: 50%;
      }

      .rhombus-content {
        margin: -130px auto 0;

        @include media-breakpoint-up(md) {
          margin: 0 auto 20px;
        }

        @include media-breakpoint-up(lg) {
          margin: 0 0 0 auto;
        }

        .rhombus-text {
          max-width: 331px;
          padding: 0;
          width: 100%;

          @include media-breakpoint-up(md) {
            max-width: 610px;
            padding-bottom: 25px;
          }

          @include media-breakpoint-up(lg) {
            padding-bottom: 40px;
            width: 470px;
          }

          p {
            margin-bottom: 20px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }

        .cta-btn-group {
          @include media-breakpoint-only(md) {
            margin: 0 auto 80px;
          }
        }
      }
    }

    .rhombus-content-container-background {
      background-color: $slate-gray;
      background-repeat: no-repeat;
      background-size: cover;
      height: 780px;
      margin-top: 76px;
      transform: rotate(3.25deg);
      width: 150%;
      z-index: -1;

      @include media-breakpoint-up(md) {
        margin-top: 218px;
      }
    }

    .why-rhombus-picture {
      background-repeat: no-repeat;
      background-size: cover;
      height: 90%;
      margin-top: 30px;
      min-height: 260px;
      width: 110%;
      z-index: -2;

      @include media-breakpoint-up(md) {
        min-height: 517px;
        width: 100%;
      }

      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }

    .why-rhombus-picture-cemeteries {
      background-position-y: 93%;

      @include media-breakpoint-up(md) {
        background-position-y: 90%;
      }

      @include media-breakpoint-up(lg) {
        background-position-y: 110%;
      }
    }
  }

  .product-lp-how-to {
    padding: 0 10px;

    @include media-breakpoint-up(md) {
      margin-top: 40px;
      padding: 0 0 80px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: -100px;
    }

    .product-lp-how-to-steps {
      .how-to {
        margin-top: 40px;

        @include media-breakpoint-up(lg) {
          margin-top: 80px;
        }

        .how-to-picture {
          background-size: 100%;
          height: 170px;
          min-width: 170px;

          @include media-breakpoint-up(lg) {
            height: 233px;
            min-width: 233px;
          }
        }

        .how-to-content {
          max-width: 331px;
          width: 100%;

          @include media-breakpoint-up(md) {
            max-width: none;
            width: 574px;
          }

          .how-to-step-label {
            color: $light-gray-text;
            font-size: 0.875rem;
            letter-spacing: 0.2px;
            margin-bottom: 10px;
          }

          h3 {
            @include media-breakpoint-between(xs, md) {
              padding: 0 35px;
            }
          }
        }
      }
    }
  }

  .cta-rhombus {
    @include media-breakpoint-between(xs, md) {
      height: 450px;

      .rhombus-text {
        padding: 0 30px;
      }

      .cta-btn-group {
        margin-top: 0;
      }
    }
  }

  .product-lp-addtional-features {
    padding-top: 40px;

    @include media-breakpoint-up(md) {
      padding-top: 70px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 80px;
    }

    .additional-features-links-container {
      margin-top: 80px;
      max-width: 1140px;

      .additional-feature {
        margin: 0 0 40px;
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 220px;
        }

        @include media-breakpoint-up(lg) {
          margin: 0 25px 40px;
          width: 236px;
        }

        .feature-icon {
          height: 54px;
        }

        p {
          color: $dark-gray-text;
        }
      }
    }
  }
}

.product-landing-page-carousel {
  background: radial-gradient(
    ellipse 100% 90% at 50% 70%,
    $radial-gradient-blue 0%,
    $radial-gradient-very-dark-teal 140%
  );
  color: $white;

  @include media-breakpoint-up(md) {
    background: radial-gradient(
      circle at 50% 40%,
      $radial-gradient-blue,
      $radial-gradient-very-dark-teal 230%
    );
  }

  &.obituary-sample-carousel {
    background: radial-gradient(
      ellipse 100% 90% at 50% 70%,
      $secondary-brand-color 0%,
      $radial-gradient-dark-teal 130%
    );
    margin-bottom: -120px;

    @include media-breakpoint-up(md) {
      background: radial-gradient(
        ellipse 100% 90% at 50% 70%,
        $secondary-brand-color 0%,
        $radial-gradient-dark-teal 100%
      );
      margin-bottom: -145px;
    }

    .rhombus-creator-top {
      margin-top: -140px;
    }

    .carousel-item {
      height: 475px;

      @include media-breakpoint-up(md) {
        height: 788px;
      }

      @include media-breakpoint-up(lg) {
        height: 1068px;
      }
    }
  }

  .rhombus-creator-top {
    margin-top: -40px;

    @include media-breakpoint-up(md) {
      margin-top: -140px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: -180px;
    }
  }

  .rhombus-content {
    margin: 160px auto 0;
    padding: 0 20px;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin-top: 200px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 260px;
      width: 973px;
    }
  }

  .non-carousel-content {
    width: 821px;

    @include media-breakpoint-between(xs, lg) {
      max-width: 100%;
      padding: 0 20px;
    }
  }

  .carousel {
    padding: 0 10px;

    @include media-breakpoint-up(md) {
      padding: 0 40px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0;
      width: 821px;
    }

    &.narrow {
      @include media-breakpoint-up(md) {
        width: 600px;
      }
    }
  }

  .carousel-indicators {
    padding: 60px 0 20px;
  }

  .carousel-item {
    height: 329px;

    @include media-breakpoint-between(xs, md) {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      height: 500px;
    }

    @include media-breakpoint-up(lg) {
      height: 631px;
    }

    .carousel-caption {
      font-size: 1.3125rem;
      padding: 0 0 40px;

      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
      }
    }
  }

  .carousel-control-prev {
    margin-left: -20px;

    @include media-breakpoint-between(xs, md) {
      opacity: 1;
    }

    @include media-breakpoint-up(md) {
      margin-left: -5px;
    }

    @include media-breakpoint-up(lg) {
      margin-left: -50px;
    }
  }

  .carousel-control-next {
    margin-right: -20px;

    @include media-breakpoint-between(xs, md) {
      opacity: 1;
    }

    @include media-breakpoint-up(md) {
      margin-right: -5px;
    }

    @include media-breakpoint-up(lg) {
      margin-right: -50px;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    margin-top: 245px;

    @include media-breakpoint-up(md) {
      margin-top: 190px;
    }
  }
}

.charities-lp {
  .charity-logo {
    width: 80px;

    @include media-breakpoint-up(md) {
      width: 100px;
    }
  }

  .product-lp-above-fold {
    @include media-breakpoint-between(xs, md) {
      max-height: 730px;
    }
  }

  .value-props-rhombus {
    height: 1480px;

    @include media-breakpoint-up(lg) {
      height: 1000px;
    }
  }

  .product-lp-value-props-container {
    @include media-breakpoint-up(md) {
      margin-top: -200px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: -80px;
    }
  }

  .charities-lp-section {
    margin: 0 auto 80px;
    max-width: 883px;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      padding: 0 40px;
    }

    &.charities-lp-overview {
      @include media-breakpoint-between(xs, md) {
        margin: 0 auto;
      }

      @include media-breakpoint-only(md) {
        margin-top: -100px;
      }
    }

    .business-faq-container {
      margin-top: 80px;
    }
  }

  .how-to-rhombus {
    height: 2020px;

    @include media-breakpoint-up(md) {
      height: 1060px;
    }

    @include media-breakpoint-up(lg) {
      height: 1500px;
    }

    .product-lp-how-to {
      @include media-breakpoint-between(xs, lg) {
        margin-top: 0;
      }
    }
  }
}

.article-widget-container {
  .articles-header-content {
    p {
      font-size: 1.3125rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
      }
    }
  }

  .articles-container {
    .article-container {
      .article-image-container {
        background-size: cover;
        height: 170px;
        min-width: 250px;

        @include media-breakpoint-up(md) {
          height: 240px;
        }

        @include media-breakpoint-up(xl) {
          height: 220px;
          min-width: 340px;
        }
      }

      p {
        color: $darker-gray-text;
        font-size: 1.125rem;
      }
    }
  }

  .read-more-articles {
    margin-bottom: 80px;

    .btn-lg {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 335px;
      }
    }
  }
}

.landing-page-extra-content-container {
  margin-bottom: 40px;
  margin-top: 80px;
  padding: 0 20px;

  @include media-breakpoint-up(md) {
    padding: 0 63px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
  }

  .extra-content-primary {
    margin-bottom: 80px;
    width: auto;

    @include media-breakpoint-up(lg) {
      margin-right: 80px;
      width: 355px;
    }
  }

  .extra-content-secondary {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 680px;
    }
  }
}

.landing-page-extra-content-states-container {
  .extra-content-secondary {
    max-height: 1072px;

    @include media-breakpoint-up(md) {
      max-height: 526px;
    }

    .state-container {
      margin-bottom: 13px;
    }
  }
}

.landing-page-faqs-container {
  .landing-page-faqs {
    margin-top: 20px;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      padding: 0 40px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 60px;
      max-width: 800px;
      padding: 0;
    }
  }

  .landing-page-faq {
    margin: 60px 0;
  }

  .btn {
    margin: 40px 0 60px;
    width: calc(100vw - 40px);

    @include media-breakpoint-up(md) {
      padding-left: 40px;
      padding-right: 40px;
      width: auto;
    }
  }
}

.testimonial-container {
  .testimonial-deceased-container {
    color: $dark-gray-text;
    margin-bottom: 40px;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin-bottom: 12%;
      width: 33%;
    }

    .testimonial-deceased-intro {
      font-size: 1.4375rem;
    }

    .testimonial-deceased-name {
      font-size: 1.375rem;
    }

    .testimonial-deceased-photo {
      @include el-border;

      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .testimonial-quote-container {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 64%;
    }

    .testimonial-quote {
      font-size: 1.375rem;
      line-height: 27px;

      @include media-breakpoint-up(md) {
        line-height: 27px;
      }

      @include media-breakpoint-up(lg) {
        line-height: 2.4375rem;
      }
    }
  }
}

.how-it-works-step {
  padding: 40px 0;

  @include media-breakpoint-up(md) {
    padding: 40px;
  }
}

.feature-bullet {
  bottom: 2px;
  font-size: 0.8375rem;
}

.feature-list-desktop {
  p {
    @include media-breakpoint-between(xs, lg) {
      font-size: 1rem !important;
    }
  }
}

.feature-list-mobile {
  list-style: none;
  padding-inline-start: 0;

  li {
    padding-bottom: 10px;

    &::before {
      color: $secondary-brand-color;
      content: "\2764\FE0E";
      display: inline-block;
      font-family: monospace;
      font-size: 2rem;
      font-weight: $font-weight-semibold;
      margin-right: 0.5rem;
      width: 1rem;
    }
  }
}

.downloadable-resource,
.funeral-resource {
  border-bottom: 1px solid $border-color;
  margin-bottom: 60px;
  padding: 0 20px 20px;
  width: 100%;

  @include media-breakpoint-up(md) {
    padding: 0 0 60px;
    width: 80%;
  }

  .resource-meta {
    margin: 0 0 20px;

    @include media-breakpoint-up(md) {
      margin: 0 100px 0 0;
    }
  }

  .resource-cta {
    flex: 0 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }

    .resource-cta-thumbnail {
      box-shadow: $card-shadow-hover;
      height: auto;
      object-fit: cover;
      width: calc(100%);

      @include media-breakpoint-up(md) {
        height: 200px;
        width: 200px;
      }

      @include media-breakpoint-up(lg) {
        height: 256px;
        width: 256px;
      }
    }
  }

  .resource-download {
    box-shadow: $card-shadow;
    flex: 0 0;
    transition: box-shadow 300ms ease-in-out;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }

    &:hover {
      box-shadow: $card-shadow-hover;
    }

    .resource-thumbnail {
      border-top-left-radius: $card-border-radius;
      border-top-right-radius: $card-border-radius;
      height: auto;
      width: 100%;

      @include media-breakpoint-up(md) {
        height: 256px;
        width: 256px;
      }
    }

    .download-text {
      padding: 10px;

      img {
        height: 24px;
        margin-top: -2px;
        width: 24px;
      }
    }
  }
}

.user-stories-container {
  padding: 0 20px;

  .user-stories-content-container {
    margin: 100px 0 0;
    max-width: 800px;

    @include media-breakpoint-up(md) {
      margin: 200px 0 100px;
    }
  }
}

.user-story {
  border-bottom: 1px solid $border-color;
  flex-direction: column;
  margin: 20px 0;
  padding: 20px 0;

  @include media-breakpoint-up(md) {
    flex-direction: row;

    &:nth-child(2n) {
      flex-direction: row-reverse;

      .user-story-meta {
        padding: 0 0 0 20px;
      }
    }
  }
}

.user-story-meta-container {
  @include media-breakpoint-up(md) {
    flex: 0 0 40%;
  }

  .user-story-meta {
    padding: 0 0 20px;

    @include media-breakpoint-up(md) {
      padding: 0 30px 0 0;
      position: sticky;
      top: 20px;
    }

    h4 {
      margin: 8px 0 2px;
    }
  }
}

.user-story-content {
  flex: 1 0 60%;
  padding-bottom: 40px;

  .link-secondary {
    img {
      margin-left: 2px;
      margin-top: -2px;
    }
  }
}
